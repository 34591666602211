<template>
  <div class="grid grid-cols-1 gap-4 xs:grid-cols-2 lg:grid-cols-4">
    <template v-if="showPrimary">
      <template v-if="loading && loadingCountPrimary">
        <div
          v-for="n in loadingCountPrimary"
          :key="n"
          class="aspect-[544/316] w-full animate-pulse rounded-lg bg-black/6 xs:col-span-full md:col-span-1 lg:col-span-2"
        />
      </template>
      <BannerListItem
        v-for="banner in primaryBanners"
        v-else
        :key="banner.entityId"
        :banner="banner"
        :is-startpage="isStartpage"
        :primary="true"
      />
    </template>

    <template v-if="showSecondary">
      <template v-if="loading && loadingCountSecondary">
        <div
          v-for="n in loadingCountSecondary"
          :key="n"
          class="aspect-square w-full animate-pulse rounded-lg bg-black/6"
        />
      </template>
      <BannerListItem
        v-for="banner in secondaryBanners"
        v-else
        :key="banner.entityId"
        :banner="banner"
        :is-startpage="isStartpage"
        :primary="false"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
interface Props {
  banners: any[];
  showPrimary?: boolean;
  showSecondary?: boolean;
  isStartpage?: boolean;
  loading?: boolean;
  loadingCountPrimary?: number;
  loadingCountSecondary?: number;
}

const props = withDefaults(defineProps<Props>(), {
  banners: () => [],
  showPrimary: true,
  showSecondary: true,
  isStartpage: false,
  loading: false,
  loadingCountPrimary: 0,
  loadingCountSecondary: 0,
});

const primaryBanners = computed(() =>
  props.banners.filter((banner) => banner.location === 'TOP')
);
const secondaryBanners = computed(() =>
  props.banners.filter((banner) => banner.location === 'BOTTOM')
);
</script>
